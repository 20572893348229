import SlabThicknessToolbarComponent from './toolbarComponents/SlabThicknessToolbarComponent';
import MyToolbarComponent from './toolbarComponents/MyToolbarComponent';
import SnippingToolbarComponent from './toolbarComponents/SnippingToolbarComponent';
import VTKMPRToolbarButton from './toolbarComponents/VTKMPRToolbarButton';

const TOOLBAR_BUTTON_TYPES = {
  COMMAND: 'command',
  SET_TOOL_ACTIVE: 'setToolActive',
};

const definitions = [
  {
    id: 'Crosshairs',
    label: 'Crosshairs',
    icon: 'crosshairs',
    //
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'enableCrosshairsTool',
    commandOptions: {},
  },
  {
    id: 'WWWC',
    label: 'WWWC',
    icon: 'level',
    //
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'enableLevelTool',
    commandOptions: {},
  },
  {
    id: 'Reset',
    label: 'Reset',
    icon: 'reset',
    //
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'resetMPRView',
    commandOptions: {},
  },

  {
    id: 'Rotate',
    label: 'Rotate',
    icon: '3d-rotate',
    //
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'enableRotateTool',
    commandOptions: {},
  },

  /*
  {
    id: 'setBlendModeToComposite',
    label: 'Disable MIP',
    icon: 'times',
    //
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'setBlendModeToComposite',
    commandOptions: {},
  },
  {
    id: 'setBlendModeToMaximumIntensity',
    label: 'Enable MIP',
    icon: 'soft-tissue',
    //
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'setBlendModeToMaximumIntensity',
    commandOptions: {},
  },

  {
    id: 'increaseSlabThickness',
    label: 'Increase Slab Thickness',
    icon: 'caret-up',
    //
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'increaseSlabThickness',
    commandOptions: {},
  },
  {
    id: 'decreaseSlabThickness',
    label: 'Decrease Slab Thickness',
    icon: 'caret-down',
    //
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'decreaseSlabThickness',
    commandOptions: {},
  },
  */
  {
    id: 'changeSlabThickness',
    label: 'Slab Thickness',
    icon: 'soft-tissue',
    CustomComponent: SlabThicknessToolbarComponent,
    commandName: 'setSlabThickness',
    actionButton: {
      id: 'setSlabThickness',
      label: 'slider',
      type: TOOLBAR_BUTTON_TYPES.COMMAND,
      commandName: 'setSlabThickness',
      commandOptions: {},
    },
    deactivateButton: {
      id: 'setBlendModeToComposite',
      type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
      commandName: 'setBlendModeToComposite',
      commandOptions: {},
    },
    operationButtons: [
      {
        id: 'setBlendModeToMaximumIntensity',
        label: 'MIP',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setBlendModeToMaximumIntensity',
        commandOptions: {},
      },
      {
        id: 'setBlendModeToMinimumIntensity',
        label: 'MinIP',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setBlendModeToMinimumIntensity',
        commandOptions: {},
      },
      {
        id: 'setBlendModeToAverageIntensity',
        label: 'AvgIP',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setBlendModeToAverageIntensity',
        commandOptions: {},
      },
    ],
  },
  {
    id: '2DMPR',
    label: '2D MPR',
    icon: 'cube',
    //
    CustomComponent: VTKMPRToolbarButton,
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'mpr2d',
    context: 'ACTIVE_VIEWPORT::CORNERSTONE',
  },
  {
    id: '3DVOLUME',
    label: '3D MPR',
    icon: 'cube',
    //
    CustomComponent: VTKMPRToolbarButton,
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'volume3d',
    commandOptions: 'abcd',
    context: 'ACTIVE_VIEWPORT::CORNERSTONE',
  },
  {
    id: 'Exit2DMPR',
    label: 'Exit 3D VOLUME',
    icon: 'times',
    //
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'setCornerstoneLayout',
    context: 'ACTIVE_VIEWPORT::VOLUME',
  },
  {
    id: 'change3DVolume',
    label: 'Slab Thickness',
    icon: 'soft-tissue',
    CustomComponent: MyToolbarComponent,
    commandName: 'setMyToolbarCheck',
    context: 'ACTIVE_VIEWPORT::VOLUME',
    actionButton: {
      id: 'setMyToolbarCheck',
      label: 'slider',
      type: TOOLBAR_BUTTON_TYPES.COMMAND,
      commandName: 'setMyToolbarCheck',
      commandOptions: {},
    },
    deactivateButton: {
      id: 'setBlendModeToComposite',
      type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
      commandName: 'setBlendModeToComposite',
      commandOptions: {},
    },
    operationButtons: [
      {
        label: 'CT-AAA',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode1',
        name: 'CT-AAA',
        id: 'vtkMRMLVolumePropertyNode1',
      },
      {
        label: 'CT-AAA2',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode2',
        id: 'vtkMRMLVolumePropertyNode2',
      },
      {
        label: 'CT-Bone',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode3',
        id: 'vtkMRMLVolumePropertyNode3',
      },
      {
        label: 'CT-Bones',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode4',
        id: 'vtkMRMLVolumePropertyNode4',
      },
      {
        label: 'CT-Cardiac',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode5',
        id: 'vtkMRMLVolumePropertyNode5',
      },
      {
        label: 'CT-Cardiac2',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode6',
        id: 'vtkMRMLVolumePropertyNode6',
      },
      {
        label: 'CT-Cardiac3',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode7',
        id: 'vtkMRMLVolumePropertyNode7',
      },
      {
        label: 'CT-Chest-Contrast-Enhanced',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode8',
        id: 'vtkMRMLVolumePropertyNode8',
      },
      {
        label: 'CT-Chest-Vessels',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode9',
        id: 'vtkMRMLVolumePropertyNode9',
      },
      {
        label: 'CT-Coronary-Arteries',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode10',
        id: 'vtkMRMLVolumePropertyNode10',
      },
      {
        label: 'CT-Coronary-Arteries-2',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode11',
        id: 'vtkMRMLVolumePropertyNode11',
      },
      {
        label: 'CT-Coronary-Arteries-3',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode12',
        id: 'vtkMRMLVolumePropertyNode12',
      },
      {
        label: 'CT-Cropped-Volume-Bone',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode13',
        id: 'vtkMRMLVolumePropertyNode13',
      },
      {
        label: 'CT-Fat',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode14',
        id: 'vtkMRMLVolumePropertyNode14',
      },
      {
        label: 'CT-Liver-Vasculature',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode15',
        id: 'vtkMRMLVolumePropertyNode15',
      },
      {
        label: 'CT-Lung',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode16',
        id: 'vtkMRMLVolumePropertyNode16',
      },
      {
        label: 'CT-MIP',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode17',
        id: 'vtkMRMLVolumePropertyNode17',
      },
      {
        label: 'CT-Muscle',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode18',
        id: 'vtkMRMLVolumePropertyNode18',
      },
      {
        label: 'CT-Pulmonary-Arteries',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode19',
        id: 'vtkMRMLVolumePropertyNode19',
      },
      {
        label: 'CT-Soft-Tissue',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode20',
        id: 'vtkMRMLVolumePropertyNode20',
      },
      {
        label: 'CT-Air',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode21',
        id: 'vtkMRMLVolumePropertyNode21',
      },
      {
        label: 'MR-Angio',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode22',
        id: 'vtkMRMLVolumePropertyNode22',
      },
      {
        label: 'MR-Default',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode23',
        id: 'vtkMRMLVolumePropertyNode23',
      },
      {
        label: 'MR-MIP',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode24',
        id: 'vtkMRMLVolumePropertyNode24',
      },
      {
        label: 'MR-T2-Brain',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode25',
        id: 'vtkMRMLVolumePropertyNode25',
      },
      {
        label: 'DTI-FA-Brain',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'vtkMRMLVolumePropertyNode26',
        id: 'vtkMRMLVolumePropertyNode26',
      },
    ],
  },
  {
    id: 'changeSnipping3DVolume',
    label: 'Snipping 3D VOLUME',
    icon: 'scissors',
    CustomComponent: SnippingToolbarComponent,
    commandName: 'snipping3DVolume',
    context: 'ACTIVE_VIEWPORT::VOLUME',
    actionButton: {
      id: 'snipping3DVolume',
      label: 'slider',
      type: TOOLBAR_BUTTON_TYPES.COMMAND,
      commandName: 'snipping3DVolume',
      commandOptions: {},
    },
    deactivateButton: {
      id: 'setBlendModeToComposite',
      type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
      commandName: 'setBlendModeToComposite',
      commandOptions: {},
    },
    operationButtons: [],
  }
];

export default {
  definitions,
  defaultContext: 'ACTIVE_VIEWPORT::VTK',
};
