import React, { Component } from 'react';
import { getImageData, loadImageData } from 'react-vtkjs-viewport';
import ConnectedVTKViewport from './ConnectedVTKViewport';
import LoadingIndicator from './LoadingIndicator.js';
import OHIF from '@ohif/core';
import PropTypes from 'prop-types';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import vtkDataArray from 'vtk.js/Sources/Common/Core/DataArray';
import vtkImageData from 'vtk.js/Sources/Common/DataModel/ImageData';
import vtkVolume from 'vtk.js/Sources/Rendering/Core/Volume';
import vtkVolumeMapper from 'vtk.js/Sources/Rendering/Core/VolumeMapper';
import presets from './presets';
import commandsModule from './commandsModule';

import vtkColorTransferFunction from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction';
import vtkPiecewiseFunction from 'vtk.js/Sources/Common/DataModel/PiecewiseFunction';
import vtkRenderWindow from 'vtk.js/Sources/Rendering/Core/RenderWindow';
// import vtkColorMaps from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction/ColorMaps';
// import vtkImageCropFilter from 'vtk.js/Sources/Filters/General/ImageCropFilter';
// import vtkHttpDataSetReader from 'vtk.js/Sources/IO/Core/HttpDataSetReader';
const segmentationModule = cornerstoneTools.getModule('segmentation');

const { StackManager } = OHIF.utils;

// TODO: Figure out where we plan to put this long term
const volumeCache = {};
const labelmapCache = {};

/**
 * Create a labelmap image with the same dimensions as our background volume.
 *
 * @param backgroundImageData vtkImageData
 */
/* TODO: Not currently used until we have drawing tools in vtkjs.
function createLabelMapImageData(backgroundImageData) {
  // TODO => Need to do something like this if we start drawing a new segmentation
  // On a vtkjs viewport.

  const labelMapData = vtkImageData.newInstance(
    backgroundImageData.get('spacing', 'origin', 'direction')
  );
  labelMapData.setDimensions(backgroundImageData.getDimensions());
  labelMapData.computeTransforms();

  const values = new Uint8Array(backgroundImageData.getNumberOfPoints());
  const dataArray = vtkDataArray.newInstance({
    numberOfComponents: 1, // labelmap with single component
    values,
  });
  labelMapData.getPointData().setScalars(dataArray);

  return labelMapData;
} */

class OHIFVTKViewport extends Component {
  state = {
    volumes: null,
    paintFilterLabelMapImageData: null,
    paintFilterBackgroundImageData: null,
    percentComplete: 0,
    isLoaded: false,
    ctTransferFunctionPresetId: 'vtkMRMLVolumePropertyNode1',
    iMinValue: 0,
    iMaxValue: 63,
    positionValue: 0,
  };

  static propTypes = {
    viewportData: PropTypes.shape({
      studies: PropTypes.array.isRequired,
      displaySet: PropTypes.shape({
        StudyInstanceUID: PropTypes.string.isRequired,
        displaySetInstanceUID: PropTypes.string.isRequired,
        sopClassUIDs: PropTypes.arrayOf(PropTypes.string),
        SOPInstanceUID: PropTypes.string,
        frameIndex: PropTypes.number,
      }),
    }),
    viewportIndex: PropTypes.number.isRequired,
    children: PropTypes.node,
    onScroll: PropTypes.func,
    servicesManager: PropTypes.object.isRequired,
  };

  static defaultProps = {
    onScroll: () => { },
  };

  static id = 'OHIFVTKViewport';

  static init() {
    console.log('OHIFVTKViewport init()');
  }

  static destroy() {
    console.log('OHIFVTKViewport destroy()');
    StackManager.clearStacks();
  }

  static getCornerstoneStack(
    studies,
    StudyInstanceUID,
    displaySetInstanceUID,
    SOPInstanceUID,
    frameIndex
  ) {
    // Create shortcut to displaySet
    const study = studies.find(
      study => study.StudyInstanceUID === StudyInstanceUID
    );

    const displaySet = study.displaySets.find(set => {
      return set.displaySetInstanceUID === displaySetInstanceUID;
    });

    // Get stack from Stack Manager
    const storedStack = StackManager.findOrCreateStack(study, displaySet);

    // Clone the stack here so we don't mutate it
    const stack = Object.assign({}, storedStack);

    if (frameIndex !== undefined) {
      stack.currentImageIdIndex = frameIndex;
    } else if (SOPInstanceUID) {
      const index = stack.imageIds.findIndex(imageId => {
        const imageIdSOPInstanceUID = cornerstone.metaData.get(
          'SOPInstanceUID',
          imageId
        );

        return imageIdSOPInstanceUID === SOPInstanceUID;
      });

      if (index > -1) {
        stack.currentImageIdIndex = index;
      }
    } else {
      stack.currentImageIdIndex = 0;
    }

    return stack;
  }

  getViewportData = (
    studies,
    StudyInstanceUID,
    displaySetInstanceUID,
    SOPClassUID,
    SOPInstanceUID,
    frameIndex
  ) => {
    // const { UINotificationService } = this.props.servicesManager.services;

    const stack = OHIFVTKViewport.getCornerstoneStack(
      studies,
      StudyInstanceUID,
      displaySetInstanceUID,
      SOPClassUID,
      SOPInstanceUID,
      frameIndex
    );

    const imageDataObject = getImageData(stack.imageIds, displaySetInstanceUID);
    let labelmapDataObject;
    let labelmapColorLUT;

    const firstImageId = stack.imageIds[0];
    const { state } = segmentationModule;
    const brushStackState = state.series[firstImageId];

    if (brushStackState) {
      const { activeLabelmapIndex } = brushStackState;
      const labelmap3D = brushStackState.labelmaps3D[activeLabelmapIndex];

      // if (
      //   brushStackState.labelmaps3D.length > 1 &&
      //   this.props.viewportIndex === 0
      // ) {
      //   UINotificationService.show({
      //     title: 'Overlapping Segmentation Found',
      //     message:
      //       'Overlapping segmentations cannot be displayed when in MPR mode',
      //     type: 'info',
      //   });
      // }

      this.segmentsDefaultProperties = labelmap3D.segmentsHidden.map(
        isHidden => {
          return { visible: !isHidden };
        }
      );

      const vtkLabelmapID = `${firstImageId}_${activeLabelmapIndex}`;

      if (labelmapCache[vtkLabelmapID]) {
        labelmapDataObject = labelmapCache[vtkLabelmapID];
      } else {
        // TODO -> We need an imageId based getter in cornerstoneTools
        const labelmapBuffer = labelmap3D.buffer;

        // Create VTK Image Data with buffer as input
        labelmapDataObject = vtkImageData.newInstance();

        const dataArray = vtkDataArray.newInstance({
          numberOfComponents: 1, // labelmap with single component
          values: new Uint16Array(labelmapBuffer),
        });

        labelmapDataObject.getPointData().setScalars(dataArray);
        labelmapDataObject.setDimensions(...imageDataObject.dimensions);
        labelmapDataObject.setSpacing(
          ...imageDataObject.vtkImageData.getSpacing()
        );
        labelmapDataObject.setOrigin(
          ...imageDataObject.vtkImageData.getOrigin()
        );
        labelmapDataObject.setDirection(
          ...imageDataObject.vtkImageData.getDirection()
        );

        // Cache the labelmap volume.
        labelmapCache[vtkLabelmapID] = labelmapDataObject;
      }

      labelmapColorLUT = state.colorLutTables[labelmap3D.colorLUTIndex];
    }

    return {
      imageDataObject,
      labelmapDataObject,
      labelmapColorLUT,
    };
  };

  /**
   *
   *
   * @param {object} imageDataObject
   * @param {object} imageDataObject.vtkImageData
   * @param {object} imageDataObject.imageMetaData0
   * @param {number} [imageDataObject.imageMetaData0.WindowWidth] - The volume's initial WindowWidth
   * @param {number} [imageDataObject.imageMetaData0.WindowCenter] - The volume's initial WindowCenter
   * @param {string} imageDataObject.imageMetaData0.Modality - CT, MR, PT, etc
   * @param {string} displaySetInstanceUID
   * @returns vtkVolumeActor
   * @memberof OHIFVTKViewport
   */
  onChangeVolume = event => {
    const { UINotificationService } = this.props.servicesManager.services;

    // if (event != 0) {
    const ctTransferFunctionPresetId = event.target.value;
    const preset = presets.find(
      preset => preset.id === ctTransferFunctionPresetId
    );
    const actor = this.state.volumes[0];
    console.log('preset', preset.name);

    this.applyPreset(actor, preset);
    console.log("🚀 ~ file: OHIFVTKViewport.js ~ line 261 ~ OHIFVTKViewport ~ preset", preset)
    // alert('Please click the screen');
    /* UINotificationService.show({
      title: `Great 👍 you clicked "${preset.name}" 3D Volume`,
      message: 'If You want to see this please click the screen ',
      type: 'info',
    }); */
    this.setState({
      ctTransferFunctionPresetId,
    });

    const renderWindow = vtkRenderWindow.newInstance();
    renderWindow.render();
    // }
  };

  setupControlPanel(data, cropFilter) {
    const axes = ['I'];
    const minmax = ['min', 'max'];

    // const extent = data.getExtent().toFixed();

    axes.forEach((ax, axi) => {
      minmax.forEach((m, mi) => {
        const el = document.querySelector(`.${ax}${m}`);
        el.setAttribute('min', data[axi * 2]);
        el.setAttribute('max', data[axi * 2 + 1]);
        el.setAttribute('value', data[axi * 2 + mi]);

        el.addEventListener('input', () => {
          const planes = cropFilter.getCroppingPlanes().slice();
          planes[axi * 2 + mi] = Number(el.value);
          cropFilter.setCroppingPlanes(...planes);
          console.log(planes);
          // renderWindow.render();
        });
      });
    });
  }

  getOrCreateVolume(
    imageDataObject,
    displaySetInstanceUID,
    ctTransferFunctionPresetId
  ) {
    var viewType = commandsModule('');
    if (volumeCache[displaySetInstanceUID]) {
      return volumeCache[displaySetInstanceUID];
    }

    const { vtkImageData, imageMetaData0 } = imageDataObject;
    // TODO -> Should update react-vtkjs-viewport and react-cornerstone-viewports
    // internals to use naturalized DICOM JSON names.
    // console.log('2355555------------->>>>>>>>>>', vtkImageData);
    const {
      windowWidth: WindowWidth,
      windowCenter: WindowCenter,
      modality: Modality,
    } = imageMetaData0;

    const { lower, upper } = _getRangeFromWindowLevels(
      WindowWidth,
      WindowCenter,
      Modality
    );
    const volumeActor = vtkVolume.newInstance();
    const volumeMapper = vtkVolumeMapper.newInstance();

    volumeActor.setMapper(volumeMapper);
    volumeMapper.setInputData(vtkImageData);
    const spacing = vtkImageData.getSpacing();
    // console.log(spacing);
    // Set the sample distance to half the mean length of one side. This is where the divide by 6 comes from.
    // https://github.com/Kitware/VTK/blob/6b559c65bb90614fb02eb6d1b9e3f0fca3fe4b0b/Rendering/VolumeOpenGL2/vtkSmartVolumeMapper.cxx#L344
    if (viewType == 'mpr2d') {
      var sampleDistance = (spacing[0] + spacing[1] + spacing[2]) / 6;
      volumeActor
        .getProperty()
        .getRGBTransferFunction(0)
        .setRange(lower, upper);
    } else {
      var sampleDistance =
        1.2 *
        Math.sqrt(
          vtkImageData
            .getSpacing()
            .map(v => v * v)
            .reduce((a, b) => a + b, 0)
        );
      var range = vtkImageData
        .getPointData()
        .getScalars()
        .getRange();
      volumeActor
        .getProperty()
        .getRGBTransferFunction(0)
        .setRange(range[0], range[1]);
    }

    //volumeMapper.setSampleDistance(sampleDistance);

    if (viewType == 'volume3d') {
      const preset = presets.find(
        preset => preset.id === ctTransferFunctionPresetId
      );
      this.applyPreset(volumeActor, preset);
      volumeActor.getProperty().setScalarOpacityUnitDistance(0, 2.5);
    }
    // Be generous to surpress warnings, as the logging really hurts performance.
    // TODO: maybe we should auto adjust samples to 1000.
    if (viewType == 'mpr2d') {
      volumeMapper.setMaximumSamplesPerRay(4000);
    }

    volumeCache[displaySetInstanceUID] = volumeActor;
    return volumeActor;
  }

  applyPreset(actor, preset) {
    const colorTransferArray = preset.colorTransfer
      .split(' ')
      .splice(1)
      .map(parseFloat);
    //2d function
    const { shiftRange } = this.getShiftRange(colorTransferArray);
    let min = shiftRange[0];
    const width = shiftRange[1] - shiftRange[0];
    const cfun = vtkColorTransferFunction.newInstance();
    const normColorTransferValuePoints = [];
    for (let i = 0; i < colorTransferArray.length; i += 4) {
      let value = colorTransferArray[i];
      const r = colorTransferArray[i + 1];
      const g = colorTransferArray[i + 2];
      const b = colorTransferArray[i + 3];

      value = (value - min) / width;
      normColorTransferValuePoints.push([value, r, g, b]);
    }

    this.applyPointsToRGBFunction(
      normColorTransferValuePoints,
      shiftRange,
      cfun
    );

    actor.getProperty().setRGBTransferFunction(0, cfun);

    // Create scalar opacity function
    const scalarOpacityArray = preset.scalarOpacity
      .split(' ')
      .splice(1)
      .map(parseFloat);

    const ofun = vtkPiecewiseFunction.newInstance();
    const normPoints = [];
    for (let i = 0; i < scalarOpacityArray.length; i += 2) {
      let value = scalarOpacityArray[i];
      const opacity = scalarOpacityArray[i + 1];

      value = (value - min) / width;

      normPoints.push([value, opacity]);
    }

    this.applyPointsToPiecewiseFunction(normPoints, shiftRange, ofun);

    actor.getProperty().setScalarOpacity(0, ofun);

    const [
      gradientMinValue,
      gradientMinOpacity,
      gradientMaxValue,
      gradientMaxOpacity,
    ] = preset.gradientOpacity
      .split(' ')
      .splice(1)
      .map(parseFloat);

    actor.getProperty().setUseGradientOpacity(0, true);
    actor.getProperty().setGradientOpacityMinimumValue(0, gradientMinValue);
    actor.getProperty().setGradientOpacityMinimumOpacity(0, gradientMinOpacity);
    actor.getProperty().setGradientOpacityMaximumValue(0, gradientMaxValue);
    actor.getProperty().setGradientOpacityMaximumOpacity(0, gradientMaxOpacity);

    if (preset.interpolation === '1') {
      actor.getProperty().setInterpolationTypeToFastLinear();
      // actor.getProperty().setInterpolationTypeToLinear();
    }

    const ambient = parseFloat(preset.ambient);
    const shade = preset.shade === '1';
    const diffuse = parseFloat(preset.diffuse);
    const specular = parseFloat(preset.specular);
    const specularPower = parseFloat(preset.specularPower);

    actor.getProperty().setShade(shade);
    actor.getProperty().setAmbient(ambient);
    actor.getProperty().setDiffuse(diffuse);
    actor.getProperty().setSpecular(specular);
    actor.getProperty().setSpecularPower(specularPower);
  }

  getShiftRange(colorTransferArray) {
    // Credit to paraview-glance
    // https://github.com/Kitware/paraview-glance/blob/3fec8eeff31e9c19ad5b6bff8e7159bd745e2ba9/src/components/controls/ColorBy/script.js#L133

    // shift range is original rgb/opacity range centered around 0
    let min = Infinity;
    let max = -Infinity;
    for (let i = 0; i < colorTransferArray.length; i += 4) {
      min = Math.min(min, colorTransferArray[i]);
      max = Math.max(max, colorTransferArray[i]);
    }

    const center = (max - min) / 2;

    return {
      shiftRange: [-center, center],
      min,
      max,
    };
  }

  applyPointsToRGBFunction(points, range, cfun) {
    const width = range[1] - range[0];
    const rescaled = points.map(([x, r, g, b]) => [
      x * width + range[0],
      r,
      g,
      b,
    ]);

    cfun.removeAllPoints();
    rescaled.forEach(([x, r, g, b]) => cfun.addRGBPoint(x, r, g, b));

    return rescaled;
  }

  applyPointsToPiecewiseFunction(points, range, pwf) {
    const width = range[1] - range[0];
    const rescaled = points.map(([x, y]) => [x * width + range[0], y]);

    pwf.removeAllPoints();
    rescaled.forEach(([x, y]) => pwf.addPoint(x, y));

    return rescaled;
  }

  setStateFromProps() {
    const { studies, displaySet } = this.props.viewportData;
    const {
      StudyInstanceUID,
      displaySetInstanceUID,
      sopClassUIDs,
      SOPInstanceUID,
      frameIndex,
    } = displaySet;

    if (sopClassUIDs.length > 1) {
      console.warn(
        'More than one SOPClassUID in the same series is not yet supported.'
      );
    }

    const study = studies.find(
      study => study.StudyInstanceUID === StudyInstanceUID
    );

    const dataDetails = {
      studyDate: study.studyDate,
      studyTime: study.studyTime,
      studyDescription: study.studyDescription,
      patientName: study.patientName,
      patientId: study.patientId,
      seriesNumber: String(displaySet.seriesNumber),
      seriesDescription: displaySet.seriesDescription,
    };

    const {
      imageDataObject,
      labelmapDataObject,
      labelmapColorLUT,
    } = this.getViewportData(
      studies,
      StudyInstanceUID,
      displaySetInstanceUID,
      SOPInstanceUID,
      frameIndex
    );

    this.imageDataObject = imageDataObject;

    /* TODO: Not currently used until we have drawing tools in vtkjs.
      if (!labelmap) {
        labelmap = createLabelMapImageData(data);
      } */

    const volumeActor = this.getOrCreateVolume(
      imageDataObject,
      displaySetInstanceUID,
      this.state.ctTransferFunctionPresetId
    );

    this.setState(
      {
        percentComplete: 0,
        dataDetails,
      },
      () => {
        this.loadProgressively(imageDataObject);

        // TODO: There must be a better way to do this.
        // We do this so that if all the data is available the react-vtkjs-viewport
        // Will render _something_ before the volumes are set and the volume
        // Construction that happens in react-vtkjs-viewport locks up the CPU.
        setTimeout(() => {
          this.setState({
            volumes: [volumeActor],
            paintFilterLabelMapImageData: labelmapDataObject,
            paintFilterBackgroundImageData: imageDataObject.vtkImageData,
            labelmapColorLUT,
          });
        }, 200);
      }
    );
  }

  componentDidMount() {
    this.setStateFromProps();
  }

  componentDidUpdate(prevProps, prevState) {
    const { displaySet } = this.props.viewportData;
    const prevDisplaySet = prevProps.viewportData.displaySet;

    if (
      displaySet.displaySetInstanceUID !==
      prevDisplaySet.displaySetInstanceUID ||
      displaySet.SOPInstanceUID !== prevDisplaySet.SOPInstanceUID ||
      displaySet.frameIndex !== prevDisplaySet.frameIndex
    ) {
      this.setStateFromProps();
    }
  }

  loadProgressively(imageDataObject) {
    loadImageData(imageDataObject);

    const { isLoading, imageIds } = imageDataObject;

    if (!isLoading) {
      this.setState({ isLoaded: true });
      return;
    }

    const NumberOfFrames = imageIds.length;

    const onPixelDataInsertedCallback = numberProcessed => {
      const percentComplete = Math.floor(
        (numberProcessed * 100) / NumberOfFrames
      );

      if (percentComplete !== this.state.percentComplete) {
        this.setState({
          percentComplete,
        });
      }
    };
    const onPixelDataInsertedErrorCallback = error => {
      const { UINotificationService } = this.props.servicesManager.services;

      if (!this.hasError) {
        if (this.props.viewportIndex === 0) {
          // Only show the notification from one viewport 1 in MPR2D.
          UINotificationService.show({
            title: 'MPR Load Error',
            message: error.message,
            type: 'error',
            autoClose: false,
          });
        }

        this.hasError = true;
      }
    };

    const onAllPixelDataInsertedCallback = () => {
      this.setState({
        isLoaded: true,
      });
    };

    imageDataObject.onPixelDataInserted(onPixelDataInsertedCallback);
    imageDataObject.onAllPixelDataInserted(onAllPixelDataInsertedCallback);
    imageDataObject.onPixelDataInsertedError(onPixelDataInsertedErrorCallback);
  }

  minHandleChange = event => {
    this.setState({
      iMinValue: event.target.value,
    });
  };

  maxHandleChange = event => {
    this.setState({
      iMaxValue: event.target.value,
    });
  };

  jMinHandleChange = event => {
    this.setState({
      positionValue: event.target.value,
    });
  };

  render() {
    let childrenWithProps = null;
    const { configuration } = segmentationModule;
    const volumeType = commandsModule('');

    // TODO: Does it make more sense to use Context?
    if (this.props.children && this.props.children.length) {
      childrenWithProps = this.props.children.map((child, index) => {
        return (
          child &&
          React.cloneElement(child, {
            viewportIndex: this.props.viewportIndex,
            key: index,
          })
        );
      });
    }

    const ctTransferFunctionPresetOptions = presets.map(preset => {
      return (
        <option key={preset.id} value={preset.id}>
          {preset.name}
        </option>
      );
    });

    const rangeStyle = {
      position: 'absolute',
      left: '25px',
      top: '25px',
      backgroundColor: 'white',
      borderRadius: '5px',
      listStyle: 'none',
      padding: '5px 10px',
      margin: '0px',
      display: 'block',
      border: '1px solid black',
      maxWidth: 'calc(100% - 70px)',
      maxHeight: 'calc(100% - 60px)',
      overflow: 'auto',
    };

    const optionStyle = {
      position: 'absolute',
      top: '4%',
      display: 'flex',
      flexDirection: 'column',
      left: '4%',
    };

    const labelStyle = {
      color: '#fff',
    };

    const style = {
      width: '100%',
      height: '100%',
      position: 'relative',
    };

    return (
      <>
        <div style={style}>
          {!this.state.isLoaded && (
            <LoadingIndicator percentComplete={this.state.percentComplete} />
          )}
          {this.state.volumes && (
            <ConnectedVTKViewport
              volumes={this.state.volumes}
              paintFilterLabelMapImageData={
                this.state.paintFilterLabelMapImageData
              }
              paintFilterBackgroundImageData={
                this.state.paintFilterBackgroundImageData
              }
              viewportIndex={this.props.viewportIndex}
              dataDetails={this.state.dataDetails}
              labelmapRenderingOptions={{
                colorLUT: this.state.labelmapColorLUT,
                globalOpacity: configuration.fillAlpha,
                visible: configuration.renderFill,
                outlineThickness: configuration.outlineWidth,
                renderOutline: configuration.renderOutline,
                segmentsDefaultProperties: this.segmentsDefaultProperties,
                onNewSegmentationRequested: () => {
                  this.setStateFromProps();
                },
              }}
              onScroll={this.props.onScroll}
            />
          )}
          {volumeType == 'volume3d' && (
            <>
              {/* <div style={optionStyle}>
                <label htmlFor="cars" style={labelStyle}>
                  3D Volume Option:
                </label>
                <div>
                  <select
                    value={this.state.ctTransferFunctionPresetId}
                    onChange={this.onChangeVolume}
                  >
                    {ctTransferFunctionPresetOptions}
                  </select>
                </div>
              </div> */}
              {/* <div style={rangeStyle}>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <b>Clip Plane 1</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Position
                        <input
                          className="plane1Position"
                          type="range"
                          min={this.state.iMinValue}
                          max={this.state.iMaxValue}
                          step="1"
                          value={this.state.positionValue}
                          onChange={this.jMinHandleChange}
                        />
                      </td>
                      <td>
                        Rotation
                        <input
                          className="plane1Rotation"
                          type="range"
                          min="0"
                          max="180"
                          step="1"
                          value="0"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Clip Plane 2</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Position
                        <input
                          className="plane2Position"
                          type="range"
                          min="-201.60000000000002"
                          max="201.60000000000002"
                          step="1"
                          value="100.80000000000001"
                        />
                      </td>
                      <td>
                        Rotation
                        <input
                          className="plane2Rotation"
                          type="range"
                          min="0"
                          max="180"
                          step="1"
                          value="0"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
            </>
          )}
        </div>
        ){childrenWithProps}
      </>
    );
  }
}

/**
 * Takes window levels and converts them to a range (lower/upper)
 * for use with VTK RGBTransferFunction
 *
 * @private
 * @param {number} [width] - the width of our window
 * @param {number} [center] - the center of our window
 * @param {string} [Modality] - 'PT', 'CT', etc.
 * @returns { lower, upper } - range
 */
function _getRangeFromWindowLevels(width, center, Modality = undefined) {
  // For PET just set the range to 0-5 SUV
  if (Modality === 'PT') {
    return { lower: 0, upper: 5 };
  }

  const levelsAreNotNumbers = isNaN(center) || isNaN(width);

  if (levelsAreNotNumbers) {
    return { lower: 0, upper: 512 };
  }

  return {
    lower: center - width / 2.0,
    upper: center + width / 2.0,
  };
}

export default OHIFVTKViewport;
