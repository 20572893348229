import throttle from 'lodash.throttle';
import {
  vtkInteractorStyleMPRWindowLevel,
  vtkInteractorStyleRotatableMPRCrosshairs,
  vtkSVGRotatableCrosshairsWidget,
  vtkInteractorStyleMPRRotate,
} from 'react-vtkjs-viewport';
import { getImageData } from 'react-vtkjs-viewport';
import { vec3 } from 'gl-matrix';
import setMPRLayout from './utils/setMPRLayout.js';
import setViewportToVTK from './utils/setViewportToVTK.js';
import Constants from 'vtk.js/Sources/Rendering/Core/VolumeMapper/Constants.js';
import OHIFVTKViewport from './OHIFVTKViewport';
import presets from './presets';

import vtkColorTransferFunction from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction';
import vtkPiecewiseFunction from 'vtk.js/Sources/Common/DataModel/PiecewiseFunction';

const { BlendMode } = Constants;
var buttonClick;

const commandsModule = ({ commandsManager }) => {
  // const { UINotificationService, LoggerService } = servicesManager.services;

  // TODO: Put this somewhere else
  let apis = {};
  let defaultVOI;

  async function _getActiveViewportVTKApi(viewports) {
    const {
      numRows,
      numColumns,
      layout,
      viewportSpecificData,
      activeViewportIndex,
    } = viewports;

    const currentData = layout.viewports[activeViewportIndex];
    if (currentData && currentData.plugin === 'vtk') {
      // TODO: I was storing/pulling this from Redux but ran into weird issues
      if (apis[activeViewportIndex]) {
        return apis[activeViewportIndex];
      }
    }

    const displaySet = viewportSpecificData[activeViewportIndex];
    let api;
    if (!api) {
      try {
        api = await setViewportToVTK(
          displaySet,
          activeViewportIndex,
          numRows,
          numColumns,
          layout,
          viewportSpecificData
        );
      } catch (error) {
        throw new Error(error);
      }
    }

    return api;
  }

  function _setView(api, sliceNormal, viewUp) {
    const renderWindow = api.genericRenderWindow.getRenderWindow();
    const istyle = renderWindow.getInteractor().getInteractorStyle();
    istyle.setSliceNormal(...sliceNormal);
    istyle.setViewUp(...viewUp);

    renderWindow.render();
  }

  function getVOIFromCornerstoneViewport() {
    const dom = commandsManager.runCommand('getActiveViewportEnabledElement');
    const cornerstoneElement = cornerstone.getEnabledElement(dom);

    if (cornerstoneElement) {
      const imageId = cornerstoneElement.image.imageId;

      const Modality = cornerstone.metaData.get('Modality', imageId);

      if (Modality !== 'PT') {
        const { windowWidth, windowCenter } = cornerstoneElement.viewport.voi;

        return {
          windowWidth,
          windowCenter,
        };
      }
    }
  }

  function getShiftRange(colorTransferArray) {
    // Credit to paraview-glance
    // https://github.com/Kitware/paraview-glance/blob/3fec8eeff31e9c19ad5b6bff8e7159bd745e2ba9/src/components/controls/ColorBy/script.js#L133

    // shift range is original rgb/opacity range centered around 0
    let min = Infinity;
    let max = -Infinity;
    for (let i = 0; i < colorTransferArray.length; i += 4) {
      min = Math.min(min, colorTransferArray[i]);
      max = Math.max(max, colorTransferArray[i]);
    }

    const center = (max - min) / 2;

    return {
      shiftRange: [-center, center],
      min,
      max,
    };
  }

  function applyPointsToRGBFunction(points, range, cfun) {
    const width = range[1] - range[0];
    const rescaled = points.map(([x, r, g, b]) => [
      x * width + range[0],
      r,
      g,
      b,
    ]);

    cfun.removeAllPoints();
    rescaled.forEach(([x, r, g, b]) => cfun.addRGBPoint(x, r, g, b));

    return rescaled;
  }

  function applyPointsToPiecewiseFunction(points, range, pwf) {
    const width = range[1] - range[0];
    const rescaled = points.map(([x, y]) => [x * width + range[0], y]);

    pwf.removeAllPoints();
    rescaled.forEach(([x, y]) => pwf.addPoint(x, y));

    return rescaled;
  }

  function applyPreset(actor, preset) {
    const colorTransferArray = preset.colorTransfer
      .split(' ')
      .splice(1)
      .map(parseFloat);
    //2d function
    const { shiftRange } = getShiftRange(colorTransferArray);
    let min = shiftRange[0];
    const width = shiftRange[1] - shiftRange[0];
    const cfun = vtkColorTransferFunction.newInstance();
    const normColorTransferValuePoints = [];
    for (let i = 0; i < colorTransferArray.length; i += 4) {
      let value = colorTransferArray[i];
      const r = colorTransferArray[i + 1];
      const g = colorTransferArray[i + 2];
      const b = colorTransferArray[i + 3];

      value = (value - min) / width;
      normColorTransferValuePoints.push([value, r, g, b]);
    }

    applyPointsToRGBFunction(
      normColorTransferValuePoints,
      shiftRange,
      cfun
    );

    actor.getProperty().setRGBTransferFunction(0, cfun);

    // Create scalar opacity function
    const scalarOpacityArray = preset.scalarOpacity
      .split(' ')
      .splice(1)
      .map(parseFloat);

    const ofun = vtkPiecewiseFunction.newInstance();
    const normPoints = [];
    for (let i = 0; i < scalarOpacityArray.length; i += 2) {
      let value = scalarOpacityArray[i];
      const opacity = scalarOpacityArray[i + 1];

      value = (value - min) / width;

      normPoints.push([value, opacity]);
    }

    applyPointsToPiecewiseFunction(normPoints, shiftRange, ofun);

    actor.getProperty().setScalarOpacity(0, ofun);

    const [
      gradientMinValue,
      gradientMinOpacity,
      gradientMaxValue,
      gradientMaxOpacity,
    ] = preset.gradientOpacity
      .split(' ')
      .splice(1)
      .map(parseFloat);

    actor.getProperty().setUseGradientOpacity(0, true);
    actor.getProperty().setGradientOpacityMinimumValue(0, gradientMinValue);
    actor.getProperty().setGradientOpacityMinimumOpacity(0, gradientMinOpacity);
    actor.getProperty().setGradientOpacityMaximumValue(0, gradientMaxValue);
    actor.getProperty().setGradientOpacityMaximumOpacity(0, gradientMaxOpacity);

    if (preset.interpolation === '1') {
      actor.getProperty().setInterpolationTypeToFastLinear();
      // actor.getProperty().setInterpolationTypeToLinear();
    }

    const ambient = parseFloat(preset.ambient);
    const shade = preset.shade === '1';
    const diffuse = parseFloat(preset.diffuse);
    const specular = parseFloat(preset.specular);
    const specularPower = parseFloat(preset.specularPower);

    actor.getProperty().setShade(shade);
    actor.getProperty().setAmbient(ambient);
    actor.getProperty().setDiffuse(diffuse);
    actor.getProperty().setSpecular(specular);
    actor.getProperty().setSpecularPower(specularPower);
  }

  function setVOI(voi) {
    const { windowWidth, windowCenter } = voi;
    const lower = windowCenter - windowWidth / 2.0;
    const upper = windowCenter + windowWidth / 2.0;

    const rgbTransferFunction = apis[0].volumes[0]
      .getProperty()
      .getRGBTransferFunction(0);

    rgbTransferFunction.setRange(lower, upper);

    apis.forEach(api => {
      api.updateVOI(windowWidth, windowCenter);
    });
  }

  const _convertModelToWorldSpace = (position, vtkImageData) => {
    const indexToWorld = vtkImageData.getIndexToWorld();
    const pos = vec3.create();

    position[0] += 0.5; /* Move to the centre of the voxel. */
    position[1] += 0.5; /* Move to the centre of the voxel. */
    position[2] += 0.5; /* Move to the centre of the voxel. */

    vec3.set(pos, position[0], position[1], position[2]);
    vec3.transformMat4(pos, pos, indexToWorld);

    return pos;
  };

  const actions = {
    getVtkApis: ({ index }) => {
      return apis[index];
    },
    resetMPRView() {
      // Reset orientation
      apis.forEach(api => api.resetOrientation());

      // Reset VOI
      if (defaultVOI) setVOI(defaultVOI);

      // Reset the crosshairs
      apis[0].svgWidgets.rotatableCrosshairsWidget.resetCrosshairs(apis, 0);
    },
    axial: async ({ viewports }) => {
      const api = await _getActiveViewportVTKApi(viewports);

      apis[viewports.activeViewportIndex] = api;

      _setView(api, [0, 0, 1], [0, -1, 0]);
    },
    sagittal: async ({ viewports }) => {
      const api = await _getActiveViewportVTKApi(viewports);

      apis[viewports.activeViewportIndex] = api;

      _setView(api, [1, 0, 0], [0, 0, 1]);
    },
    coronal: async ({ viewports }) => {
      const api = await _getActiveViewportVTKApi(viewports);

      apis[viewports.activeViewportIndex] = api;

      _setView(api, [0, 1, 0], [0, 0, 1]);
    },
    requestNewSegmentation: async ({ viewports }) => {
      const allViewports = Object.values(viewports.viewportSpecificData);
      const promises = allViewports.map(async (viewport, viewportIndex) => {
        let api = apis[viewportIndex];

        if (!api) {
          api = await _getActiveViewportVTKApi(viewports);
          apis[viewportIndex] = api;
        }

        api.requestNewSegmentation();
        api.updateImage();
      });
      await Promise.all(promises);
    },
    jumpToSlice: async ({
      viewports,
      studies,
      StudyInstanceUID,
      displaySetInstanceUID,
      SOPClassUID,
      SOPInstanceUID,
      segmentNumber,
      frameIndex,
      frame,
      done = () => { },
    }) => {
      let api = apis[viewports.activeViewportIndex];

      if (!api) {
        api = await _getActiveViewportVTKApi(viewports);
        apis[viewports.activeViewportIndex] = api;
      }

      const stack = OHIFVTKViewport.getCornerstoneStack(
        studies,
        StudyInstanceUID,
        displaySetInstanceUID,
        SOPClassUID,
        SOPInstanceUID,
        frameIndex
      );

      const imageDataObject = getImageData(
        stack.imageIds,
        displaySetInstanceUID
      );

      let pixelIndex = 0;
      let x = 0;
      let y = 0;
      let count = 0;

      const rows = imageDataObject.dimensions[1];
      const cols = imageDataObject.dimensions[0];

      for (let j = 0; j < rows; j++) {
        for (let i = 0; i < cols; i++) {
          // [i, j] =
          const pixel = frame.pixelData[pixelIndex];
          if (pixel === segmentNumber) {
            x += i;
            y += j;
            count++;
          }
          pixelIndex++;
        }
      }
      x /= count;
      y /= count;

      const position = [x, y, frameIndex];
      const worldPos = _convertModelToWorldSpace(
        position,
        imageDataObject.vtkImageData
      );

      api.svgWidgets.rotatableCrosshairsWidget.moveCrosshairs(worldPos, apis);
      done();
    },
    setSegmentationConfiguration: async ({
      viewports,
      globalOpacity,
      visible,
      renderOutline,
      outlineThickness,
    }) => {
      const allViewports = Object.values(viewports.viewportSpecificData);
      const promises = allViewports.map(async (viewport, viewportIndex) => {
        let api = apis[viewportIndex];

        if (!api) {
          api = await _getActiveViewportVTKApi(viewports);
          apis[viewportIndex] = api;
        }

        api.setGlobalOpacity(globalOpacity);
        api.setVisibility(visible);
        api.setOutlineThickness(outlineThickness);
        api.setOutlineRendering(renderOutline);
        api.updateImage();
      });
      await Promise.all(promises);
    },
    setSegmentConfiguration: async ({ viewports, visible, segmentNumber }) => {
      const allViewports = Object.values(viewports.viewportSpecificData);
      const promises = allViewports.map(async (viewport, viewportIndex) => {
        let api = apis[viewportIndex];

        if (!api) {
          api = await _getActiveViewportVTKApi(viewports);
          apis[viewportIndex] = api;
        }

        api.setSegmentVisibility(segmentNumber, visible);
        api.updateImage();
      });
      await Promise.all(promises);
    },
    enableRotateTool: () => {
      apis.forEach((api, apiIndex) => {
        const istyle = vtkInteractorStyleMPRRotate.newInstance();

        api.setInteractorStyle({
          istyle,
          configuration: { apis, apiIndex, uid: api.uid },
        });
      });
    },
    enableCrosshairsTool: () => {
      apis.forEach((api, apiIndex) => {
        const istyle = vtkInteractorStyleRotatableMPRCrosshairs.newInstance();

        api.setInteractorStyle({
          istyle,
          configuration: {
            apis,
            apiIndex,
            uid: api.uid,
          },
        });
      });

      const rotatableCrosshairsWidget =
        apis[0].svgWidgets.rotatableCrosshairsWidget;

      const referenceLines = rotatableCrosshairsWidget.getReferenceLines();

      // Initilise crosshairs if not initialised.
      if (!referenceLines) {
        rotatableCrosshairsWidget.resetCrosshairs(apis, 0);
      }
    },
    enableLevelTool: () => {
      function updateVOI(apis, windowWidth, windowCenter) {
        apis.forEach(api => {
          api.updateVOI(windowWidth, windowCenter);
        });
      }

      const throttledUpdateVOIs = throttle(updateVOI, 16, { trailing: true }); // ~ 60 fps

      const callbacks = {
        setOnLevelsChanged: ({ windowCenter, windowWidth }) => {
          apis.forEach(api => {
            const renderWindow = api.genericRenderWindow.getRenderWindow();

            renderWindow.render();
          });

          throttledUpdateVOIs(apis, windowWidth, windowCenter);
        },
      };

      apis.forEach((api, apiIndex) => {
        const istyle = vtkInteractorStyleMPRWindowLevel.newInstance();

        api.setInteractorStyle({
          istyle,
          callbacks,
          configuration: { apis, apiIndex, uid: api.uid },
        });
      });
    },
    setSlabThickness: ({ slabThickness }) => {
      apis.forEach(api => {
        api.setSlabThickness(slabThickness);
      });
    },
    changeSlabThickness: ({ change }) => {
      apis.forEach(api => {
        const slabThickness = Math.max(api.getSlabThickness() + change, 0.1);

        api.setSlabThickness(slabThickness);
      });
    },
    setBlendModeToComposite: () => {
      apis.forEach(api => {
        const renderWindow = api.genericRenderWindow.getRenderWindow();
        const istyle = renderWindow.getInteractor().getInteractorStyle();

        const slabThickness = api.getSlabThickness();

        const mapper = api.volumes[0].getMapper();
        if (mapper.setBlendModeToComposite) {
          mapper.setBlendModeToComposite();
        }

        if (istyle.setSlabThickness) {
          istyle.setSlabThickness(slabThickness);
        }
        renderWindow.render();
      });
      console.log("🚀 ~ file: commandsModule.js ~ line 518 ~ commandsModule ~ apis", apis)
    },
    setBlendModeToMaximumIntensity: () => {
      apis.forEach(api => {
        const renderWindow = api.genericRenderWindow.getRenderWindow();
        const mapper = api.volumes[0].getMapper();
        if (mapper.setBlendModeToMaximumIntensity) {
          mapper.setBlendModeToMaximumIntensity();
        }
        renderWindow.render();
      });
    },
    setBlendMode: ({ blendMode }) => {
      apis.forEach(api => {
        const renderWindow = api.genericRenderWindow.getRenderWindow();

        api.volumes[0].getMapper().setBlendMode(blendMode);

        renderWindow.render();
      });
    },
    mpr2d: async ({ viewports }) => {
      // TODO push a lot of this backdoor logic lower down to the library level.
      const displaySet = viewports.viewportSpecificData[viewports.activeViewportIndex];

      // Get current VOI if cornerstone viewport.
      const cornerstoneVOI = getVOIFromCornerstoneViewport();
      defaultVOI = cornerstoneVOI;

      const viewportProps = [
        {
          //Axial
          orientation: {
            sliceNormal: [0, 0, 1],
            viewUp: [0, -1, 0],
          },
        },
        {
          // Sagittal
          orientation: {
            sliceNormal: [1, 0, 0],
            viewUp: [0, 0, 1],
          },
        },
        {
          // Coronal
          orientation: {
            sliceNormal: [0, 1, 0],
            viewUp: [0, 0, 1],
          },
        },
      ];

      try {
        apis = await setMPRLayout(displaySet, viewportProps, 1, 3);
      } catch (error) {
        throw new Error(error);
      }

      if (cornerstoneVOI) {
        setVOI(cornerstoneVOI);
      }

      // Add widgets and set default interactorStyle of each viewport.
      apis.forEach((api, apiIndex) => {
        api.addSVGWidget(
          vtkSVGRotatableCrosshairsWidget.newInstance(),
          'rotatableCrosshairsWidget'
        );

        const uid = api.uid;
        const istyle = vtkInteractorStyleRotatableMPRCrosshairs.newInstance();

        api.setInteractorStyle({
          istyle,
          configuration: { apis, apiIndex, uid },
        });

        api.svgWidgets.rotatableCrosshairsWidget.setApiIndex(apiIndex);
        api.svgWidgets.rotatableCrosshairsWidget.setApis(apis);
      });

      const firstApi = apis[0];

      // Initialise crosshairs
      apis[0].svgWidgets.rotatableCrosshairsWidget.resetCrosshairs(apis, 0);

      // Check if we have full WebGL 2 support
      const openGLRenderWindow = apis[0].genericRenderWindow.getOpenGLRenderWindow();

      if (!openGLRenderWindow.getWebgl2()) {
        // Throw a warning if we don't have WebGL 2 support,
        // And the volume is too big to fit in a 2D texture

        const openGLContext = openGLRenderWindow.getContext();
        const maxTextureSizeInBytes = openGLContext.getParameter(
          openGLContext.MAX_TEXTURE_SIZE
        );

        const maxBufferLengthFloat32 =
          (maxTextureSizeInBytes * maxTextureSizeInBytes) / 4;

        const dimensions = firstApi.volumes[0]
          .getMapper()
          .getInputData()
          .getDimensions();

        const volumeLength = dimensions[0] * dimensions[1] * dimensions[2];

        if (volumeLength > maxBufferLengthFloat32) {
          const message =
            'This volume is too large to fit in WebGL 1 textures and will display incorrectly. Please use a different browser to view this data';
          // LoggerService.error({ message });
          UINotificationService.show({
            title: 'Browser does not support WebGL 2',
            message,
            type: 'error',
            autoClose: false,
          });
          console.log(message);
        }
      }
    },
    volume3d: async ({ viewports }) => {
      buttonClick = 'volume3d';
      // TODO push a lot of this backdoor logic lower down to the library level.
      const displaySet = viewports.viewportSpecificData[viewports.activeViewportIndex];
      const cornerstoneVOI = getVOIFromCornerstoneViewport();

      const viewportProps = [
        {
          //Axial
          orientation: {
            sliceNormal: [0, 0, 1],
            viewUp: [0, -1, 0],
          },
        },
      ];

      try {
        apis = await setMPRLayout(displaySet, viewportProps, 1, 1);
      } catch (error) {
        throw new Error(error);
      }

      if (cornerstoneVOI) {
        // setVOI(cornerstoneVOI);
      }
    },
    change3DVolume: () => {
      apis.forEach(api => {
        const renderWindow = api.genericRenderWindow.getRenderWindow();
        renderWindow.render();
      });
    },
    setMyToolbarCheck: () => {
      apis.forEach(api => {
        const renderWindow = api.genericRenderWindow.getRenderWindow();
        renderWindow.render();
      });
    },
    vtkMRMLVolumePropertyNode1: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[0]);
      });
    },
    vtkMRMLVolumePropertyNode2: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[1]);
      });
    },
    vtkMRMLVolumePropertyNode3: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[2]);
      });
    },
    vtkMRMLVolumePropertyNode4: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[3]);
      });
    },
    vtkMRMLVolumePropertyNode5: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[4]);
      });
    },
    vtkMRMLVolumePropertyNode6: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[5]);
      });
    },
    vtkMRMLVolumePropertyNode7: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[6]);
      });
    },
    vtkMRMLVolumePropertyNode8: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[7]);
      });
    },
    vtkMRMLVolumePropertyNode9: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[8]);
      });
    },
    vtkMRMLVolumePropertyNode10: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[9]);
      });
    },
    vtkMRMLVolumePropertyNode11: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[10]);
      });
    },
    vtkMRMLVolumePropertyNode12: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[11]);
      });
    },
    vtkMRMLVolumePropertyNode13: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[12]);
      });
    },
    vtkMRMLVolumePropertyNode14: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[13]);
      });
    },
    vtkMRMLVolumePropertyNode15: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[14]);
      });
    },
    vtkMRMLVolumePropertyNode16: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[15]);
      });
    },
    vtkMRMLVolumePropertyNode17: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[16]);
      });
    },
    vtkMRMLVolumePropertyNode18: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[17]);
      });
    },
    vtkMRMLVolumePropertyNode19: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[18]);
      });
    },
    vtkMRMLVolumePropertyNode20: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[19]);
      });
    },
    vtkMRMLVolumePropertyNode21: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[20]);
      });
    },
    vtkMRMLVolumePropertyNode22: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[21]);
      });
    },
    vtkMRMLVolumePropertyNode23: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[22]);
      });
    },
    vtkMRMLVolumePropertyNode24: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[23]);
      });
    },
    vtkMRMLVolumePropertyNode25: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[24]);
      });
    },
    vtkMRMLVolumePropertyNode26: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[25]);
      });
    },
    vtkMRMLVolumePropertyNode27: () => {
      apis.forEach(api => {
        const actor = api.volumes[0];
        applyPreset(actor, presets[26]);
      });
    },
    snipping3DVolume: ({ slabThickness }) => {
      apis.forEach(api => {

      });
    },
  };

  window.vtkActions = actions;

  const definitions = {
    requestNewSegmentation: {
      commandFn: actions.requestNewSegmentation,
      storeContexts: ['viewports'],
      options: {},
    },
    jumpToSlice: {
      commandFn: actions.jumpToSlice,
      storeContexts: ['viewports'],
      options: {},
    },
    setSegmentationConfiguration: {
      commandFn: actions.setSegmentationConfiguration,
      storeContexts: ['viewports'],
      options: {},
    },
    setSegmentConfiguration: {
      commandFn: actions.setSegmentConfiguration,
      storeContexts: ['viewports'],
      options: {},
    },
    axial: {
      commandFn: actions.axial,
      storeContexts: ['viewports'],
      options: {},
    },
    coronal: {
      commandFn: actions.coronal,
      storeContexts: ['viewports'],
      options: {},
    },
    sagittal: {
      commandFn: actions.sagittal,
      storeContexts: ['viewports'],
      options: {},
    },
    enableRotateTool: {
      commandFn: actions.enableRotateTool,
      options: {},
    },
    enableCrosshairsTool: {
      commandFn: actions.enableCrosshairsTool,
      options: {},
    },
    enableLevelTool: {
      commandFn: actions.enableLevelTool,
      options: {},
    },
    resetMPRView: {
      commandFn: actions.resetMPRView,
      options: {},
    },
    setBlendModeToComposite: {
      commandFn: actions.setBlendModeToComposite,
      options: { blendMode: BlendMode.COMPOSITE_BLEND },
    },
    setBlendModeToMaximumIntensity: {
      commandFn: actions.setBlendModeToMaximumIntensity,
      options: { blendMode: BlendMode.MAXIMUM_INTENSITY_BLEND },
    },
    setBlendModeToMinimumIntensity: {
      commandFn: actions.setBlendMode,
      options: { blendMode: BlendMode.MINIMUM_INTENSITY_BLEND },
    },
    setBlendModeToAverageIntensity: {
      commandFn: actions.setBlendMode,
      options: { blendMode: BlendMode.AVERAGE_INTENSITY_BLEND },
    },
    setSlabThickness: {
      // TODO: How do we pass in a function argument?
      commandFn: actions.setSlabThickness,
      options: {},
    },
    increaseSlabThickness: {
      commandFn: actions.changeSlabThickness,
      options: {
        change: 3,
      },
    },
    decreaseSlabThickness: {
      commandFn: actions.changeSlabThickness,
      options: {
        change: -3,
      },
    },
    mpr2d: {
      commandFn: actions.mpr2d,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    volume3d: {
      commandFn: actions.volume3d,
      storeContexts: ['viewports'],
      options: 'efgh',
      context: 'VIEWER',
    },
    snipping3DMPR: {
      commandFn: actions.snipping3DMPR,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    setMyToolbarCheck: {
      commandFn: actions.setMyToolbarCheck,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    change3DVolume: {
      commandFn: actions.change3DVolume,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode1: {
      commandFn: actions.vtkMRMLVolumePropertyNode1,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode2: {
      commandFn: actions.vtkMRMLVolumePropertyNode2,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode3: {
      commandFn: actions.vtkMRMLVolumePropertyNode3,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode4: {
      commandFn: actions.vtkMRMLVolumePropertyNode4,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode5: {
      commandFn: actions.vtkMRMLVolumePropertyNode5,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode6: {
      commandFn: actions.vtkMRMLVolumePropertyNode6,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode7: {
      commandFn: actions.vtkMRMLVolumePropertyNode7,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode8: {
      commandFn: actions.vtkMRMLVolumePropertyNode8,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode9: {
      commandFn: actions.vtkMRMLVolumePropertyNode9,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode10: {
      commandFn: actions.vtkMRMLVolumePropertyNode10,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode11: {
      commandFn: actions.vtkMRMLVolumePropertyNode11,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode12: {
      commandFn: actions.vtkMRMLVolumePropertyNode12,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode13: {
      commandFn: actions.vtkMRMLVolumePropertyNode13,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode14: {
      commandFn: actions.vtkMRMLVolumePropertyNode14,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode15: {
      commandFn: actions.vtkMRMLVolumePropertyNode15,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode16: {
      commandFn: actions.vtkMRMLVolumePropertyNode16,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode17: {
      commandFn: actions.vtkMRMLVolumePropertyNode17,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode18: {
      commandFn: actions.vtkMRMLVolumePropertyNode18,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode19: {
      commandFn: actions.vtkMRMLVolumePropertyNode19,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode20: {
      commandFn: actions.vtkMRMLVolumePropertyNode20,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode21: {
      commandFn: actions.vtkMRMLVolumePropertyNode21,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode22: {
      commandFn: actions.vtkMRMLVolumePropertyNode22,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode23: {
      commandFn: actions.vtkMRMLVolumePropertyNode23,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode24: {
      commandFn: actions.vtkMRMLVolumePropertyNode24,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode25: {
      commandFn: actions.vtkMRMLVolumePropertyNode25,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    vtkMRMLVolumePropertyNode26: {
      commandFn: actions.vtkMRMLVolumePropertyNode26,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    snipping3DVolume: {
      commandFn: actions.snipping3DVolume,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    getVtkApiForViewportIndex: {
      commandFn: actions.getVtkApis,
      context: 'VIEWER',
    },
  };

  // return {
  //   definitions,
  //   defaultContext: 'ACTIVE_VIEWPORT::VTK',
  // };
  if (commandsManager == undefined) {
    // alert('commandmodule' + commandsManager);
    return buttonClick;
  } else {
    return {
      definitions,
      defaultContext: 'ACTIVE_VIEWPORT::VTK',
    };
  }
};

export default commandsModule;
